
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError,
    forgotPasswordSuccess,
    forgotPasswordError,
    resetPasswordSuccess,
    resetPasswordError
} from './actions';
import { setCredentials, deCredential } from '../../helpers/CodeeCredentials';



export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (name, email, password) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            name, 
            email,
            password
        })
    };
    
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'user/register', requestOptions);
    const data = await response.json();
    return data;
}        

function* registerWithEmailPassword({ payload }) {
    const { name, email, password } = payload.user;

    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, name, email, password);
        if ((registerUser.status) && (registerUser.status===200)) {

            // do login
            let loginUser = yield call(loginWithCpfPasswordAsync, email, password);
            if ((loginUser.status) && (loginUser.status===200)) {
                loginUser = setCredentials(loginUser);
                
                yield put(registerUserSuccess(loginUser.body));
            } else {
                if (loginUser.message) {
                    yield put(registerUserError(registerUser.message));
                } else {
                    yield put(registerUserError("NOMESSAGE"));
                }
            }
            //end do login


            
        } else {
            if (registerUser.message) {
                yield put(registerUserError(registerUser.message));
            } else {
                yield put(registerUserError("NOMESSAGE"));
            }
        }

    } catch (error) {
        yield put(registerUserError(error));
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithCpfPassword);
}

const loginWithCpfPasswordAsync = async (cpf, password) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            cpf,
            password
        })
    };
    const response = await fetch(process.env.REACT_APP_HASURA_API_URL + `userAction/login?cpf=${cpf}&password=${password}`, requestOptions);
    const data = await response.json();
    return data;
}
        

function* loginWithCpfPassword({ payload }) {

    const cpf = payload.user.cpf;
    const password = payload.user.password

    try {
        
        let loginUser = yield call(loginWithCpfPasswordAsync, cpf, password);
        
        
        if ((loginUser) && (loginUser.status===true)) {
            //logado
            
            loginUser = setCredentials(loginUser);
            yield put(loginUserSuccess(loginUser));
        } else {
            if (loginUser.message) {
                yield put(loginUserError(loginUser.message));
            } else {
                yield put(loginUserError("user.user-blocked"));
            }
        }


    } catch (error) {
        yield put(loginUserError(error));

    }
}


export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
    //history.push('/')
}

function* logout({ payload }) {
    const { history } = payload
    try {
        yield call(logoutAsync, history);
        deCredential(history);
        yield put(registerUserSuccess(false));

    } catch (error) {
    }
}
export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
    /*
    return await auth.sendPasswordResetEmail(email)
        .then(user => user)
        .catch(error => error);
    */    
    return;    
}

function* forgotPassword({ payload }) {
    const { email } = payload.forgotUserMail;
    try {
        const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
        if (!forgotPasswordStatus) {
            yield put(forgotPasswordSuccess("success"));
        } else {
            yield put(forgotPasswordError(forgotPasswordStatus.message));
        }
    } catch (error) {
        yield put(forgotPasswordError(error));

    }
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
    /*
    return await auth.confirmPasswordReset(resetPasswordCode, newPassword)
        .then(user => user)
        .catch(error => error);
    */    
    return;    
}

function* resetPassword({ payload }) {
    const { newPassword, resetPasswordCode } = payload;
    try {
        const resetPasswordStatus = yield call(resetPasswordAsync, resetPasswordCode, newPassword);
        if (!resetPasswordStatus) {
            yield put(resetPasswordSuccess("success"));
        } else {
            yield put(resetPasswordError(resetPasswordStatus.message));
        }
    } catch (error) {
        yield put(resetPasswordError(error));

    }
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgotPassword),
        fork(watchResetPassword),
    ]);
}